// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'app/styles/bkw-blue' as bkw;
// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the theme object.
$gpt4km-theme: bkw.$light-theme;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($gpt4km-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
@include mat.typography-hierarchy($gpt4km-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($gpt4km-theme);
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Klint';
  font-weight: 400;
  font-style: normal;
  src: url("https://static.bkw.ch/fonts/KlintforBKWW05-Regular.woff2") format('woff2');
}


html, body {
  --mat-menu-container-color: white;
  margin: 0; height: 100vh; min-height: 100vh;
}

@supports (height: 100dvh) {
    html, body {
        height: 100dvh; min-height: 100dvh;
    }
}

.delete-icon {
  @include mat.icon-color($gpt4km-theme, $color-variant: error);
}

:root {

  --sidepanel-background-color: #{mat.get-theme-color(
                  bkw.$light-theme,
                  secondary,
                  95)};

  --user-question-background-color: var(--sidepanel-background-color);

  --chatbot-answer-hover-background-color: #{mat.get-theme-color(
                  bkw.$light-theme,
                  secondary,
                  98)};
  --chatbot-answer-content-table-header-background-color: var(--chatbot-answer-hover-background-color);
  --chatbot-answer-background-color: #{mat.get-theme-color(
                  bkw.$light-theme,
                  secondary,
                  99)};
  --contentpanel-background-color: #ffffff;

    --conversation-icon-color: #{mat.get-theme-color(
                    bkw.$light-theme,
                    secondary,
                    70)};

  --info-dialog-header-color: #{mat.get-theme-color(
                    bkw.$light-theme,
                    secondary,
                    70)};

  .delete-button {
    @include mat.button-overrides((
            filled-container-color: red,
            filled-label-text-color: white,
    ));
  }

  .error-dialog-button {
    @include mat.button-color(bkw.$light-theme, $color-variant: error);
  }

  mat-toolbar {
    --mat-toolbar-container-background-color: var(--contentpanel-background-color);
  }
}