.br-square {
  --_br-active-color: #4278F5;
  --_br-font-size: 30px;
  --br-width: var(--_br-font-size);
  --br-height: var(--_br-font-size);

  .br-units {
    counter-reset: counter;
  }

  .br-unit-clone {
    .br-unit-inner {
      background-color: transparent;

      &:after {
        content: none !important;
      }
    }
  }

  .br-unit-inner {
    width: var(--br-width);
    height: var(--br-height);
    border: 2px solid var(--_br-active-color);
    background-color: white;
    margin: 2px;
    text-decoration: none;
    font-size: 14px;
    line-height: 2;
    text-align: center;
    color: var(--_br-active-color);
    font-weight: 600;
    opacity: 0.25;

    &.br-active {
      opacity: 1;
    }

    &:after {
      content: counter(counter);
      counter-increment: counter;
    }
  }

  &.br-readonly {

    .br-active {
      opacity: 0.1;
    }
  }
}
